<template>
  <div class="contaner_w">
    <new-header :config="headerConfig"></new-header>

    <div class="content_record">
      <div class="header_box">
        <div @click="selectBtn(1)" :class="select ? 'bar' : ''">生效中</div>
        <div @click="selectBtn(2)" :class="select ? '' : 'bar'">已作废</div>
      </div>
    </div>

    <div v-if="status == 1" class="content_time">
      <div class="dateText">
        <div>时间查询</div>
        <div class="date_box">
          <div @click="startBtn(1)">{{ this.queryList.start_time || "开始时间" }}</div>
          ~
          <div @click="startBtn(2)">{{ this.queryList.end_time || "结束时间" }}</div>
        </div>
        <div @click="flayBtn" style="color: #007dff">
          高级查询
          <van-icon v-if="flayShow" name="arrow-down" />
          <van-icon v-else name="arrow-up" />
        </div>
      </div>

      <div v-if="flayShow==false">
        <div class="dateText">
          <div>合同查询</div>
          <div class="date_box1">
            <input
              v-model="content_sn"
              @input="inputChange"
              placeholder-class="plass"
              placeholder="编号查询"
            />
          </div>
        </div>

        <div class="dateText">
          <div>合同筛选</div>
          <div class="date_box1 detas" @click="startBtn(3)">{{ typeText }}</div>
        </div>
      </div>
      <vue-loadmore :on-refresh="onRefresh" :on-loadmore="onLoad" :finished="finished">
        <div class="list_box" v-for="(item, index) in dataList" :key="index">
          <div class="left_box">
            <div class="title">合同名称： {{ item.contract_name }}</div>
            <div>合同编号：{{ item.contract_no }}</div>
            <div>合同类型：{{ item.contract_type == 1 ? "销售合同" : "股东合同" }}</div>
            <div>获取时间：{{ item.create_time }}</div>
          </div>
          <div>
            <div class="price">{{ item.contract_shares }}</div>
            <div>工分</div>
            <div class="look" @click="lookBtn(item.contract_file)">
              查看详情 <img src="../../assets/Down Arrow 1@2x (1).png" />
            </div>
          </div>
        </div>
      </vue-loadmore>
    </div>

    <div v-else class="content_time_s">
      <div class="header_top">
        <div>合同信息</div>
        <div>作废时间</div>
      </div>
      <div class="content_box">
        <vue-loadmore :on-refresh="onRefresh" :on-loadmore="onLoad" :finished="finished">
          <div class="list_box_two" v-for="(item, index) in dataList" :key="index">
            <div class="left_box">
              <div>合同编号：{{ item.contract_no }}</div>
              <div>合同类型：{{ item.contract_type == 1 ? "销售合同" : "合约合同" }}</div>
              <div>获取时间：{{ item.create_time }}</div>
            </div>
            <div class="right_box">
              <div>{{ item.invalid_time }}</div>
              <div class="error_btn">{{ item.invalid_scene_desc }}</div>
            </div>
          </div>
        </vue-loadmore>
      </div>
    </div>

    <div v-if="dateTimeShow">
      <custom-data-time
        @confirmBtn="confirmBtn"
        @onCancel="onCancel"
        :config="configOne"
      ></custom-data-time>
    </div>
    <div v-if="typeShow">
      <custom-data-time @onConfirm="confirmBtnSelect" :config="config"></custom-data-time>
    </div>

    <van-dialog v-model="show" title="合同查看" width="90%" show-cancel-button :showConfirmButton="false" @confirm="confirm">
      <div class="pdf_box">
        <pdf
          @num-pages="setNumPages"
          v-for="item in numPages"
          :key="item"
          :src="pdfSrc"
          :page="item"
          ref="pdf"
        ></pdf>
        <van-loading
          v-if="locationShow"
          type="spinner"
          color="#1989fa"
          class="loadingClass"
          size="30px"
          >加载中...</van-loading
        >
      </div>
    </van-dialog>

    <div>
      <van-loading v-if="orderShow" size="24px">加载中...</van-loading>
      <van-empty
        v-if="dataList.length == 0"
        class="custom-image"
        image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
        description="暂无数据"
      />
    </div>
  </div>
</template>
<script type>
import customDataTime from "../../components/custom/customDataTime/index";
import newHeader from "@/components/newHeader";

import { user_contract_api } from "@/api/admin";
import moment from "../../utils/moment";
// import { ImagePreview } from 'vant';
import pdf from "vue-pdf";
export default {
  data() {
    return {
      headerConfig: {
        show: true,
        title: '申请记录',
      },

      dateTimeShow: false,
      show: false,
      select: true,
      typeShow: false,
      iframeSrc: "",
      index: 1,
      pageCount: 0,
      locationShow: false,
      radioConfig: {
        value: "",
        list: [
          { checked: true, title: "增值兑换", ladel: 1 },
          { checked: false, title: "分享补金兑换", ladel: 2 },
        ],
      },
      queryList: {
        page: 1,
        limit: 10,
        contract_state: 1,
        start_time: "",
        end_time: "",
      },
      dataList: [],
      finished: false,
      status: 1,
      config: {
        columns: ["全部", "销售", "工分"],
        type: "text",
        title: "合同类型",
      },
      configOne: {
        type: "date",
        title: "时间查询",
        index: "",
      },
      numPages: null,
      pdfSrc: "",
      typeText: "全部",
      content_sn: "",
      orderShow: false,
      flayShow: true,
    };
  },
  components: {
    newHeader,
    customDataTime,
    pdf,
  },
  created() {
    document.title = "";
    this.getList();
  },
  methods: {
    startBtn(index) {
      if (index == 3) {
        this.typeShow = true;
      } else {
        this.configOne.index = index;
        this.dateTimeShow = true;
      }
    },
    setNumPages() {
      console.log(23);
    },
    // 预览合同
    lookBtn(img) {
      this.show = true;
      // ImagePreview([img]);
      this.pdfSrc = img;
      // this.iframeSrc = img
      let loadingTask = pdf.createLoadingTask(img);
      loadingTask.promise
        .then((pdf) => {
          this.numPages = pdf.numPages;
          this.locationShow = true;
          setTimeout((e) => {
            console.log(e);
            this.locationShow = false;
          }, 5000);
        })
        .catch((err) => {
          console.error("pdf 加载失败", err);
        });
    },
    // 选择时间
    confirmBtn(data, index) {
      this.orderShow = true;
      this.queryList.page = 1;
      this.dataList = [];
      if (index == 1) {
        this.queryList.start_time = moment(data.getTime()).format("yyyy-MM-dd");
      } else {
        this.queryList.end_time = moment(data.getTime()).format("yyyy-MM-dd");
      }
      this.dateTimeShow = false;
      // console.log(this.queryList,moment(data.getTime()).format("yyyy-MM-dd"),"this.queryList")
      this.getList();
    },
    confirmBtnSelect(e) {
      this.orderShow = true;
      this.queryList.page = 1;
      this.dataList = [];
      this.typeText = e;
      if (e == "销售") {
        this.queryList.contract_type = 1;
      } else if (e == "工分") {
        this.queryList.contract_type = 2;
      } else {
        this.queryList.contract_type = "";
      }
      this.getList();
      this.typeShow = false;
    },
    onCancel() {
      this.typeShow = false;
    },
    inputChange() {
      this.orderShow = true;
      this.queryList.page = 1;
      this.dataList = [];
      this.queryList.contract_no = this.content_sn;
      this.getList();
    },
    getList() {
      user_contract_api(this.queryList).then((res) => {
        this.orderShow = false;
        if (res.code == 0) {
          if (this.headerBtnShow) {
            this.total = res.pagination.total;
          }
          this.dataList = this.dataList.concat(res.data);
        }
      });
    },
    submitBtn() {
      this.show = true;
    },
    selectBtn(index) {
      this.index = index;
      this.status = index;
      this.queryList.page = 1;
      this.dataList = [];
      if (index == 1) {
        this.select = true;
        this.queryList.contract_state = 1;
        this.getList();
      } else {
        this.select = false;
        this.queryList.contract_state = 0;
        this.getList();
      }
    },
    goDetails(id, item) {
      if (this.index == 1) {
        if (item.pay_info[0].order_id) {
          this.$router.push(`./nextAuditNew?id=${id}`);
        } else {
          this.$router.push(`./nextAudit?id=${id}`);
        }
      } else {
        this.$router.push(`./nextAuditNew?id=${id}`);
      }
    },
    // 上拉加载------------------
    onRefresh(done) {
      this.dataList = [];
      this.queryList.page == 1;
      this.finished = false;
      this.fetch();
      done();
    },
    onLoad(done) {
      console.log(12);
      // if (this.queryList.page <= 10) {
      this.queryList.page++;
      this.fetch();
      // } else {
      // all data loaded
      this.finished = true;
      // }
      done();
    },

    fetch() {
      this.getList();
    },
    typeBtn() {
      this.typeShow = true;
    },
    confirm() {
      const x = new window.XMLHttpRequest();
      x.open("GET", this.pdfSrc, true);
      x.responseType = "blob";
      x.onload = () => {
        const url = window.URL.createObjectURL(x.response);
        const a = document.createElement("a");
        a.href = url;
        a.download = "合同";
        a.click();
      };
      x.send();
    },

    flayBtn() {
      this.flayShow = !this.flayShow;
    },
  },
};
</script>
<style lang="less" scoped>
.contaner_w {
  .van-dialog {
    top: 50% !important;
  }
  background-color: #f9f9fb;
  position: relative;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  .list_box {
    width: 690px;
    height: 200px;
    background: linear-gradient(180deg, #c1e4ff 0%, #ffffff 59%);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.05);
    border-radius: 20px 20px 20px 20px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 24px;
    color: rgba(26, 26, 26, 0.8);
    padding: 20px 27px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .price {
      font-family: SF UI Display, SF UI Display;
      font-weight: bold;
      font-size: 32px;
      color: #ff535a;
      margin-top: 25px;
      margin-bottom: 10px;
    }
    .look {
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 28px;
      color: #007dff;
      margin-top: 16px;
      img {
        width: 12px;
        height: 20px;
        margin-left: 5px;
      }
    }
    .left_box {
      .title {
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        font-size: 26px;
        color: #1a1a1a;
      }
      > div {
        margin-bottom: 16px;
      }
    }
  }
  .dateText {
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 30px;
    color: #333333;
    display: flex;
    line-height: 66px;
    margin-bottom: 21px;
    position: relative;
    z-index: 99;
    .date_box {
      width: 400px;
      height: 66px;
      background: #f6f6f6;
      border-radius: 10px 10px 10px 10px;
      margin: 0 5px;
      display: flex;
      input {
        width: 520px;
        height: 66px;
        background: #f6f6f6;
        border-radius: 10px 10px 10px 10px;
        border: none;
      }
      > div {
        flex: 0.9;
        text-align: center;
        color: #aaa;
      }
    }
    .detas {
      padding: 0 20px;
    }
    .date_box1 {
      width: 530px;
      height: 66px;
      background: #f6f6f6;
      border-radius: 10px 10px 10px 10px;
      margin: 0 18px;
      border: none;
      display: flex;

      box-sizing: border-box;
      font-size: 26px;
      color: rgba(51, 51, 51, 0.5);

      input {
        width: 100%;
        height: 100%;
        background: #f6f6f6;
        border-radius: 10px 10px 10px 10px;
        border: none;
        padding-left: 20px;
        box-sizing: border-box;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 26px;
        color: rgba(51, 51, 51, 0.5);
      }
      > div {
        flex: 0.9;
        text-align: center;
        color: #aaa;
      }
    }
  }
  .content_time {
    width: 100%;
    background-color: #fff;
    margin-top: 25px;
    padding: 34px 29px;
    box-sizing: border-box;
  }
  .content_time_s {
    width: 100%;
    background-color: #fff;
    margin-top: 25px;
    padding: 30px 0;
    .list_box_two {
      width: 690px;
      height: 180px;
      display: flex;
      justify-content: space-between;
      background: linear-gradient(180deg, #c1e4ff 0%, #ffffff 55%);
      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.05);
      border-radius: 20px 20px 20px 20px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 24px;
      color: rgba(26, 26, 26, 0.8);
      padding: 30px 26px;
      box-sizing: border-box;
      margin-bottom: 20px;
      .left_box {
        > div {
          margin-bottom: 16px;
        }
      }
      .right_box {
        width: 150px;
        text-align: center;
      }
      .error_btn {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 28px;
        color: #007dff;
        margin-top: 24px;
      }
    }
    .header_top {
      display: flex;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 30px;
      color: #333333;
      padding-bottom: 30px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      > div {
        flex: 1;
        text-align: center;
      }
    }
    .content_box {
      padding: 30px;
      box-sizing: border-box;
    }
  }
}

.content_record {
  padding: 30px;
  box-sizing: border-box;

  .header_box {
    width: 690px;
    height: 72px;
    background: #ffffff;
    border-radius: 10px 10px 10px 10px;
    display: flex;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 28px;
    color: rgba(26, 26, 26, 0.8);
    text-align: center;
    line-height: 72px;

    div {
      width: 345px;
      height: 72px;
      background: #fff;
      border-radius: 10px 10px 10px 10px;
    }

    .bar {
      width: 345px;
      height: 80px;
      background: #007dff;
      border-radius: 16px 16px 16px 16px;
      color: #fff;
    }
  }
}
.pdf_box {
  height: 80vh;
  overflow-y: scroll;
  position: relative;
  .loadingClass {
    position: fixed;
    left: 30%;
    top: 30%;
  }
}
.plass {
  color: rgba(51, 51, 51, 0.5);
}
</style>
